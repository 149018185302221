<template>
  <div class="songs-title">
    <div class="songs-title__title" :class="{'songs-title__title--is-playing': isPlaying}">
      {{ songTitle }}
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { usePlayerStore } from '../stores/player';

const playerStore = usePlayerStore();
const { isPlaying, songTitle } = storeToRefs(playerStore);
</script>

<style lang="scss">
.songs-title {
  display: flex;
  flex: 1 25%;
  justify-content: center;
  align-items: flex-start;
  margin: 0 10px 10px;

  &__title {
    display: flex;
    padding: 10px 20px 9px;
    border-radius: 15px;
    margin: 14px 0 0;
    color: #eee;
    background: linear-gradient(90deg, #ffa495, 70% #f19589);
    // border: 3px solid #FF6A88;
    box-shadow: darken(#ffabbdcd, 10%) -3px -3px 3px,
                #db6f85 3px 3px 3px,
                #db6f85 2px 2px 3px inset,
                #db6f85 2px 2px 20px inset;
    font-family: 'Varela Round';
    font-weight: bold;
    text-align: center;
    transition: background-color 0.2s ease, color 0.2s ease;
    z-index: 10000;

    @media (prefers-color-scheme: dark) {
      background: linear-gradient(90deg, var(--light-hex-color), 70% var(--dark-hex-color));
      box-shadow: var(--dark-hex-color) -3px -3px 3px,
                  var(--light-hex-color) 3px 3px 3px,
                  var(--dark-hex-color) 2px 2px 3px inset,
                  var(--light-hex-color ) 2px 2px 20px inset;
    }

    &--is-playing {
      background-color: #ffa495;
      color: #efefef;

      @media (prefers-color-scheme: dark) {
        background-color: var(--light-hex-color);
      }
    }
  }
}
</style>