<template>
  <button class="songs-menu btn-neu2" @click="showPlaylists">
    <img :src="Menu" />
  </button>
</template>

<script setup>
import { useRouter } from 'vue-router';

import Menu from '../assets/svg/menu.svg';

const router = useRouter();

function showPlaylists() {
  router.push('/playlists');
};
</script>

<style lang="scss">
.songs-menu {
  align-items: center;
  justify-content: center;
  align-self: flex-end;
  flex: 1 0 auto;
  margin: 0 0 0 5px;
  height: 46px;
  width: 48px;
  border-radius: 18px;
  border-radius: 16px;
  background: transparent;
  // box-shadow: -10px -10px 16px lighten(#FF9A8B, 1%),
  //              10px 10px 16px darken(#dd8578, 1%),
  //           inset 1px 1px 2px transparent, //#ffabbdd4
  //          inset -1px -1px 2px transparent; //#db6f85

  &::before {
    top: 4px;
    left: 4px;
    height: 38px;
    width: 40px;
    border-radius: 14px;
    border-radius: 12px;
    background: transparent;
    // box-shadow: -1px -1px 2px lighten(#FF9A8B, 3%), //
    //                1px 1px 2px darken(#FF9A8B, 2%), //
    //          inset 1px 1px 3px lighten(#FF9A8B, 6%), // inset 2px 2px 2px #ffabbdd4,
    //          inset -1px -1px 3px darken(#FF9A8B, 10%); // inset -2px -2px 2px #db6f85;
  }

  &:hover {
    background: transparent;
    // box-shadow: -1px -1px 2px lighten(#FF9A8B, 3%), //
    //                1px 1px 2px darken(#FF9A8B, 2%), //
    //          inset 1px 1px 3px lighten(#FF9A8B, 6%), // inset 2px 2px 2px #ffabbdd4,
    //          inset -1px -1px 3px darken(#FF9A8B, 20%); // inset -2px -2px 2px #db6f85;


    &:before {
      background: transparent;
      // box-shadow: -8px -8px 16px lighten(#FF9A8B, 3%),
      //              8px 8px 16px darken(#FF9A8B, 2%),
      //        inset 0px 0px 3px lighten(#FF9A8B, 6%), // inset 2px 2px 2px #ffabbdd4,
      //       inset -0px -0px 3px darken(#FF9A8B, 10%); // inset -2px -2px 2px #db6f85;
    }
  }

  >img {
    height: 22px;
    width: 22px;

    fill {
      color: #edeaea;
    }
  }
}
</style>