<template>
  <div class="playlists-controls">
    <button class="playlists-controls__back btn-neu2"
            @click="goBackHome">
      <span>&#10132;</span>
    </button>
    <button class="playlists-controls__show btn-neu2"
            @click="emit('showNewPlaylistTemplate')">
      <span>&#43;</span>
    </button>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router';

const router = useRouter();

const emit = defineEmits(['showNewPlaylistTemplate']);

const goBackHome = () => {
  router.push('/');
};
</script>

<style lang="scss">
.playlists-controls { // playlists
  display: flex;
  flex: 1;
  align-items: flex-end;
  z-index: 1000;

  &__back,
  &__show { // playlists
    margin: 20px;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    // // background: #FF9A8B;
    // //     box-shadow: 20px 20px 30px #d98276, 
    // //               -20px -20px 30px #ffb0a0,
    // //         inset -20px -20px 60px #ef9684,
    // //           inset 20px 20px 60px #ef9684;
    // transition: box-shadow 0.25s ease, background-color 0.25s ease, transform 0.2s ease;
    z-index: 1600;

    &::before {
      top: 4px;
      border-radius: 50%;
      left: 4px;
      height: 52px;
      width: 52px;
      // box-shadow: 20px 20px 30px #d98276, 
      //           -20px -20px 30px #ffb0a0,
      //       inset 20px 20px 60px #ffb0a0,
      //     inset -20px -20px 60px #d98276;
      transition: box-shadow 0.2s ease;
    }

    &:hover {
      // box-shadow: 10px 10px 30px #d98276,
      //           -10px -10px 30px #ffb0a0,
      //       inset 10px 10px 30px #d98276,
      //     inset -10px -10px 30px #ffb0a0;        
      // // background: #FF998B;
      // background: lighten(#FF998B, 5%);

      &::before {
        top: 4px;
        border-radius: 50%;
        left: 4px;
        height: 52px;
        width: 52px;
        // box-shadow: 10px 10px 20px #ffb0a0, 
        //           -10px -10px 20px #d98276,
        //       inset 10px 10px 30px #d98276,
        //       inset -5px -5px 10px #ffb0a0;
        // background: lighten(#FF998B, 5%);
      }
    }

    > span {
      color: #eee;
      font-weight: 500;
      z-index: 1600;
    }

    &:first-of-type > span {
      font-size: 35px;
      transform: rotate(180deg);
    }

    &:nth-of-type(2) > span {
      font-size: 50px;
    }
  }
}
</style>