import { defineStore } from 'pinia';
import { computed, reactive, ref } from 'vue';
import { useStorage, useRefHistory } from '@vueuse/core';
import { mergician } from 'mergician';

export const usePlayerStore = defineStore('player', () => {
  const playlists = useStorage(
    'vvibe-playlists', 
    {
      0: {
        title: 'vvibe',
        isCurrent: false,
        id: 'vvibe',
        songs: [
          {name: "https://www.youtube.com/watch?v=rAaNfCg51Xc",
            key: "2gfowefhip23"},
          {name: "https://www.youtube.com/watch?v=ISM8gQS5Jn4",
            key: "dq3gf443t63"},
          // {name: "https://www.youtube.com/watch?v=2nN0OA_6K0c&list=LL",
          //   key: "dsawe2adf"},
          {name: "https://www.youtube.com/watch?v=yfOsHX7zFN8",
            time: '1:22',
          key: "dlodwe2d"},
          // {name: "https://www.youtube.com/watch?v=96UeGr1pueY",
          // key: "l89yfe9y83a"},
          {name: "https://www.youtube.com/watch?v=tHpfMruktsc",
          key: "loglfdsds"},
          {name: "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/977665876&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true",
          key: "dq33faf3"},
          {name: "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1118029840&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true%22",
          key: "da4ws4sfr"},
          {name: "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1200564529&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true",
          key: "df3w4tyuj6r"},
          // {name: "https://open.spotify.com/embed/track/4tRhRLBxIZ34Iw0eCuiC03?utm_source=generator"},
          // {name: "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/312514291",
          // key: "gyju6ijfkgy"}
        ]
      },
      1: {
        title: 'phonk',
        isCurrent: false,
        id: 'phonk',
        songs: [
          {name: "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/977665876&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true",
          key: "dq33faf3"},
          {name: "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1118029840&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true%22",
          key: "da4ws4sfr"},
          {name: "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1200564529&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true",
          key: "df3w4tyuj6r"},
        ],
      }
    }, localStorage,
    {
      mergeDefaults: (storageValue, defaults) => {
        return mergician({
          appendArrays: false,
          dedupArrays: true,
        })(storageValue, defaults)
        // return defaults;
      }
    });

  const playlist = computed({
    get() {
      const currentPlaylistKey = Object.keys(playlists.value).find((key) => (playlists.value[parseInt(key, 10)].isCurrent)) ?? 0;
      return playlists.value[currentPlaylistKey]
    },
    set(playlist) {
      const currentPlaylistKey = Object.keys(playlists.value).find((key) => (playlists.value[key].isCurrent));
      playlists.value[currentPlaylistKey].songs = playlist;
    }
  });
  // const { history, undo, redo } = useRefHistory(playlist, { deep: true });

  const isYTApiReady = ref(false); // YOUTUBE //
  window.onYouTubeIframeAPIReady = () => {
    isYTApiReady.value = true;
  };
  const isSCApiReady = ref(false); // SOUNDCLOUD //
  let checkSCApiReady = setInterval(() => {
    if (window.SC) {
      isSCApiReady.value = true;
      clearInterval(checkSCApiReady);
    }
  }, 500);
  const isPlaylistReady = computed(() => (playlist.value.songs.every((song) => (song.ready)) || playlist.value.songs[0].ready));

  const showAddSongInput = ref(false); // used in AddCard, MenuCard, SongsInputDialog (so it is not used in current scope)
  const songs = computed(() => {
    if (isYTApiReady.value && isSCApiReady.value) {
      return playlist.value.songs.length && playlist.value.songs;
    }
    return [];
  });
  const activeSong = reactive({
    id: 0,
    state: 'pause',
    position: 0
  });
  const songTitle = computed(() => (playlist.value?.songs[activeSong.id]?.title));
  const songCardWidth = ref(367); // SongCard width 335px + 2 * padding 16px
  const isSetByUser = ref(false); // was action performed by user
  const songCardVisiblePosition = ref(0);
  const isPlaying = computed(() => (activeSong.state === 'play'));
  function setActiveSongState(state) { // TODO: delete //
    activeSong.state = state;
  };
  function playSong() {
    isSetByUser.value = true;
    setActiveSongState(activeSong.state === 'pause' ? 'play' : 'pause'); // TODO: delete, change value directly in active song //
  };
  function setActiveSong(id, shouldPositionToAddedSong = false) {
    activeSong.id = id;
    activeSong.state = 'play';
    positionSong(shouldPositionToAddedSong);
  };
  function playNextSong() {
    isSetByUser.value = true;
    if (activeSong.id + 1 < songs.value.length) {
      activeSong.id = activeSong.id + 1;
      activeSong.state = 'play';
      positionSong();
    }
    // console.log({
    //   'activeSongId': activeSong.id,
    //   'songsLength': songs.value.length
    // });
    // if (activeSong.id + 1 === songs.value.length) {
    //   activeSong.state = 'pause'
    // }
  };
  function playPrevSong() {
    isSetByUser.value = true;
    if (activeSong.id - 1 >= 0) {
      activeSong.id = activeSong.id - 1;
      activeSong.state = 'play';
      positionSong();
    }
  };

  function positionSong(shouldPositionToAddedSong = false) {
    if (shouldPositionToAddedSong) {
      activeSong.position = 0;
      return;
    }
    songCardVisiblePosition.value = activeSong.position + (Math.floor(document.body.clientWidth / songCardWidth.value) - 1);
    // set next SongCard position at pos.0 if next SongCard to be played is not visible on screen
    // it's to fix breaking UI on Iframe focus when Iframe is overflowing, and it also looks like nice UX interaction design :)
    if (Math.abs((activeSong.id + 1) - (activeSong.position + 1)) > (songCardVisiblePosition.value - activeSong.position)) {
      activeSong.position = activeSong.id;
      return;
    }
    if ((activeSong.id + 1) < (activeSong.position + 1)) { // set active song position on playPrevSong // there is some error when playing song and quickly changing next song position, but that's most likely connected to functionality above
      activeSong.position = activeSong.id;
    }
  };
  function positionNextSong() {
    if (Math.abs(activeSong.position) >= songs.value.length - 1) return;
    activeSong.position = activeSong.position + 1;
  };
  function positionPreviousSong() {
    if (activeSong.position === 0) return;
    activeSong.position = activeSong.position - 1;
  };

  // watch(() => playlist, (value) => {
  //   debugger;
  // });

  return {
    playlists,
    playlist,
    showAddSongInput,
    songs,
    activeSong,
    songTitle,
    songCardWidth,
    isSetByUser,
    isPlaying,
    isPlaylistReady,
    playSong,
    setActiveSong,
    setActiveSongState,
    playNextSong,
    playPrevSong,
    positionSong,
    positionNextSong,
    positionPreviousSong
  };
});
