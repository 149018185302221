<template>
  <div v-if="!isNewPlaylistTemplate" class="playlist playlist--frosted-cube">
    <div id="playlistHeader" class="playlist__header" @click.stop="showPlaylist">
      <div class="playlist__title" v-if="!isPlaylistTitleEditable">{{ playlistTitle }}</div>
      <div class="playlist__edit">
        <div v-if="!isPlaylistTitleEditable" class="playlist__edit-btn btn-neu2" @click.stop="editPlaylist"><span>&#9998;</span></div>
        <div v-else class="playlist--add-new__wrapper">
          <input id="titleInput" class="playlist__input"
              placeholder="Set playlist title"
              :value="modelValue"
              @input="updateTitle"
              @keydown.enter="updateTitle"/>
          <button class="playlist__add"
                  @click.stop="editPlaylist">
            <div>&#10162;</div>
          </button>
        </div>
      </div>
    </div>
    <div class="playlist__count">{{ songsCount }}</div>
  </div>

  <div v-else class="playlist playlist--add-new">
    <div class="playlist--add-new__wrapper">
      <input ref="newPlaylist" class="playlist__input"
             placeholder="Set playlist title"
             :value="modelValue"
             @input="emit('update:modelValue', $event.target.value)"
             @keydown.enter="emit('addPlaylist')" />
      <button class="playlist__add"
              @click="emit('addPlaylist')">
        <div>&#10162;</div>
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
const props = defineProps({
  modelValue: {
    type: String,
    default: ''
  },
  playlistTitle: {
    type: String,
    default: ''
  },
  songsCount: {
    type: Number,
    default: 0
  },
  isNewPlaylistTemplate: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits([
  'update:modelValue',
  'showPlaylist',
  'addPlaylist'
]);
const isPlaylistTitleEditable = ref(false);

const editPlaylist = (event) => {
  isPlaylistTitleEditable.value = !isPlaylistTitleEditable.value
};

const updateTitle = (event) => {
  if (event.key !== 'Enter') return;
  emit('update:modelValue', event.target.value)
};

const showPlaylist = (event) => {
  if (event.target.id === 'titleInput') return; 
  emit('showPlaylist');
};
</script>

<style lang="scss" scoped>
.playlist {
  $this: &;

  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  justify-self: flex-start;
  flex: 0 0 calc(33% - 80px);
  flex-wrap: wrap;
  min-height: 200px;
  min-width: 255px;
  max-width: 255px;
  color: #eee;
  margin: 40px;
  padding: 20px;
  background: linear-gradient(145deg, #ffa495, #e68a7d);
  // background: #FF998B;
  box-shadow: 20px 20px 60px darken(#d98276, 2%),
    -20px -20px 60px lighten(#ffb0a0, 2%);
  background: linear-gradient(145deg, #ffa495, #e68a7d);
  border-radius: 2px;
  transition: box-shadow 0.2s ease;
  cursor: pointer;
  z-index: 1800;

  @media (prefers-color-scheme: dark) {
    background: linear-gradient(145deg, var(--light-hex-color), var(--dark-hex-color));
    box-shadow: 20px 20px 60px var(--dark-hex-color),
               -20px -20px 60px var(--light-hex-color);
  }

  &__header {
    display: flex;
    flex: 1;
    justify-content: space-between;
    z-index: 1700;
  }

  &__title {
    flex: 1;
    // min-width: 0;
    max-width: 150px;
    white-space: wrap;
    overflow: hidden;
    text-overflow: ellipsis;

    font-family: 'Varela Round', sans-serif;
    font-weight: 600;
    font-size: 20px;
  }

  &__edit {
    flex: 0;

    > .playlist__edit-btn {
      width: 46px;
      height: 46px;
      white-space: nowrap;
      border-radius: 50%;
      font-size: 28px;
      color: rgb(246, 223, 208);

      > span {
        transform: rotate(90deg);
      }

      &:hover {
        color: rgb(247, 207, 181);
      }

      &:before,
      &:after {
        top: 4px;
        left: 4px;
        width: 38px;
        height: 38px;
        border-radius: 50%;
      }
    }
  }

  &__count {
    font-family: 'Poppins', sans-serif;
    color: #e0e5eb;
    font-size: 25px;
    z-index: 1700;
  }

  &--add-new__wrapper {
    position: relative;
    display: flex;
    flex: 0;
    z-index: 1700;
    max-width: 215px; // 215 px //
    

    .playlist__add {
      position: absolute;
      content: '';
      top: 5px;
      right: 8px;
      background: transparent;
      border: 0;
      font-weight: 400;
      font-size: 25px;
      border-radius: 50%;
      border: 2px solid #e68a7d;
      color: #e68a7d;
      text-shadow: -0 -0 3px rgba(255, 255, 255, 0.4),
                    0 0 3px rgba(0, 0, 0, 0.08);
      box-shadow: 1px 1px 2px darken(#d98276, 2%),
                 -1px -1px 3px lighten(#ffb0a0, 2%),
            inset 1px 1px 2px darken(#d98276, 2%),
           inset -1px -1px 2px lighten(#ffb0a0, 2%);
      cursor: pointer;
      z-index: 1800;

      @media (prefers-color-scheme: dark) {
        border-color: var(--light-hex-color);
        color: var(--light-hex-color);
        box-shadow: 1px 1px 2px var(--dark-hex-color),
                   -1px -1px 3px var(--light-hex-color),
              inset 1px 1px 2px var(--dark-hex-color),
             inset -1px -1px 2px var(--light-hex-color);
      }

      > div {
        transform: rotate(-90deg);
      }
  }

  .playlist__input {
      background: transparent;
      caret-color: lightblue;
      z-index: 1700;
      color: #eee;
      max-height: 45px;
      line-height: 25px;
      font-size: 22px;
      width: 215px; // 215 px //
      padding: 15px 46px 15px 15px;
      border: 0;
      border-radius: 25px;
      box-shadow: 2px 2px 3px lighten(#ffb0a0, 1%),
                 -2px -2px 3px darken(#d98276, 1%),
            inset 2px 2px 2px darken(#d98276, 1%),
           inset -2px -2px 2px lighten(#ffb0a0, 1%);

      @media (prefers-color-scheme: dark) {
        box-shadow: 2px 2px 3px var(--light-hex-color),
                   -2px -2px 3px var(--dark-hex-color),
              inset 2px 2px 2px var(--dark-hex-color),
             inset -2px -2px 2px var(--light-hex-color);
      }

      &:focus+.playlist__add {
        // box-shadow: 2px 2px 3px lighten(#ffb0a0, 1%),
        //        -2px -2px 3px darken(#d98276, 1%);
      }

      &:active {
        outline: 0;
      }

      &::placeholder {
        color: #f5d5d5;
      }
    }
  }

  &::after,
  &:before {
    position: absolute;
    content: '';
    top: 2.5%;
    left: 2.5%;
    // right: 0;
    // bottom: 0;
    width: 95%;
    height: 95%;
    border-radius: 2px;
    opacity: 0;
    transition: opacity 0.2s ease, box-shadow 0.2s ease;
    z-index: 1500;
    background: transparent;
  }

  &:before {
    opacity: 1;
    // background: linear-gradient(145deg, #ffa495, #e68a7d);
    box-shadow: 0 0 0 lighten(#ffb0a0, 1%),
                1px 2px 3px darken(#d98276, 1%);

    @media (prefers-color-scheme: dark) {
      box-shadow: 0 0 0 var(--light-hex-color),
                  1px 2px 3px var(--dark-hex-color);
    }
  }

  &:after {
    // background: #FF998B;
    opacity: 0;
    box-shadow: 8px 8px 14px lighten(#ffb0a0, 1%),
               -8px -8px 14px darken(#d98276, 1%),
          inset 8px 8px 16px darken(#d98276, 1%),
         inset -8px -8px 16px lighten(#ffb0a0, 1%);

    @media (prefers-color-scheme: dark) {
      box-shadow: 8px 8px 14px var(--light-hex-color),
                 -8px -8px 14px var(--dark-hex-color),
            inset 8px 8px 16px var(--dark-hex-color),
           inset -8px -8px 16px var(--light-hex-color);
    }
  }

  &:hover {
    box-shadow: 11px 11px 26px darken(#d98276, 2%),
               -11px -11px 26px lighten(#ffb0a0, 2%);

    @media (prefers-color-scheme: dark) {
      box-shadow: 11px 11px 26px var(--dark-hex-color),
                 -11px -11px 26px var(--light-hex-color);
    }

    &:before {
      box-shadow: 0 0 0 lighten(#ffb0a0, 0.1%),
                  1px 1px 3px darken(#d98276, 1%);

      @media (prefers-color-scheme: dark) {
        box-shadow: 0 0 0 var(--light-hex-color)
                    1px 1px 3px var(--dark-hex-color);
      }
    }
  }

  &.playlist--frosted-cube {
    &:after,
    &:before {
      width: 95.25%;
      height: 99%;
      top: 3.5%;
    }

    &:before {
      box-shadow: 0 0 1px lighten(#ffb0a0, 1%),
                  0 1px 3px darken(#d98276, 1%);

      @media (prefers-color-scheme: dark) {
        box-shadow: 0 0 1px var(--light-hex-color),
                    0 1px 3px var(--dark-hex-color);
      }
    }

    &:hover {
      box-shadow: 11px 11px 28px darken(#d98276, 2%),
                 -11px -11px 28px lighten(#ffb0a0, 2%);

      @media (prefers-color-scheme: dark) {
        box-shadow: 11px 11px 28px var(--dark-hex-color),
                   -11px -11px 28px var(--light-hex-color);
      }
    }
  }
}
</style>