<template>
  <div class="songs" ref="songsRef" @keydown="positionOrPlaySongs">
    <div class="songs__header">
      <div class="songs__header-wrapper">
        <songs-menu />
        <div class="songs__playlist-title" @click="showPlaylist">
          <div>{{ playlist?.title }}</div>
          <!-- <songs-copy /> -->
        </div>
      </div>
      <songs-add />
    </div>
    <div class="songs__view" :style="cssVars">
      <!-- <add-card/> -->
      <div ref="songsScroll" class="songs__carousel">
        <song-card
          v-for="(song, index) in songs"
          :key="song.key"
          :url="song.name"
          :id="index"
          :time="song.time"
        />
      </div>
    </div>
    <songs-nav />
    <songs-title />
  </div>
</template>

<script setup>
import { ref, computed, toRefs, onMounted, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useRouter, useRoute } from 'vue-router';
import { useResizeObserver, useScroll, watchDebounced } from '@vueuse/core';

import { usePlayerStore } from '../stores/player';

// import AddCard from '../components/AddCard.vue';
import SongCard from '../components/SongCard.vue';
import SongsNav from '../components/SongsNav.vue';
import SongsTitle from '../components/SongsTitle.vue';
// import SongsCopy from '../components/SongsCopy.vue';
import SongsAdd from '../components/SongsAdd.vue';
import SongsMenu from '../components/SongsMenu.vue';

const router = useRouter();
const route = useRoute();

const playerStore = usePlayerStore();

const { songs, songCardWidth, playlists, playlist } = storeToRefs(playerStore);
const { activeSong, positionPreviousSong, positionNextSong, playPrevSong, playNextSong } = playerStore;
const { position, id } = toRefs(activeSong);

const positionOrPlaySongs = ({ target, key }) => {
  if (target.id === 'songs-add__input') return;
  
  if (key === 'ArrowLeft') positionPreviousSong();
  if (key === 'ArrowRight') positionNextSong();
  if (key === ',') playPrevSong(); // <
  if (key === '.') playNextSong(); // >
};

const showPlaylist = () => {
  const playlistIndex = Object.values(playlists.value).findIndex((item) => (playlist.value.id === item.id));
  router.push(`/playlist/${playlistIndex}`);
};

const songsRef = ref(null);
const songsWidth = ref(null);
useResizeObserver(songsRef, (entries) => {
  songsWidth.value = entries[0].contentRect.width;
});
const isSongsScrollRightArrived = ref(false);
const isLastSongCardVisible = computed(() => ( // if all songCards widht is bigger
  ((songsWidth.value > songCardWidth.value * (songs.value.length - position.value) - songCardWidth.value + 20))
  || isSongsScrollRightArrived.value
));
const isFirstSongCardVisible = computed(() => (position.value === 0 || songsWidth.value < 400));
const songsScroll = ref(null);
const { x, arrivedState } = useScroll(songsScroll, {behavior: 'smooth'});
watch(() => position.value, (newPosition) => {
  x.value = songCardWidth.value * newPosition
});
const cssVars = computed(() => ({
  // '--transform-list-x': `${isScrolled.value ? songCardWidth.value * position.value * -1 : ''}px`,
  '--transform-list-x': 0,
  '--opacity-list-right-shadow': isLastSongCardVisible.value ? 0 : 1,
  '--opacity-list-left-shadow': isFirstSongCardVisible.value ? 0 : 1
}));

onMounted(() => {
  position.value = 0;
  id.value = 0;
});

watchDebounced(arrivedState, (scrollArrivedState) => {
  isSongsScrollRightArrived.value = scrollArrivedState.right;
}, { debounce: 10 });

// window.SC.Events.ready
</script>

<style lang="scss">
.songs {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  z-index: 1000;
  transition: transform 0.5s ease;

  // &--collapsed {
  //   transform: rotate3d(8,-1,0.7, 70deg) translateY(500px) translateX(50px);
  //   transform-style: preserve-3d;
  // }

  &__header {
    display: flex;
    flex: 1 25%;
    justify-content: space-between;
    width: 100%;
    padding: 0 25px 10px 15px;
  }

  &__header-wrapper {
    display: flex;
    align-items: center;
  }

  &__playlist-title {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 -4px 20px;
    font-size: 42px;
    font-family: 'Varela Round';
    font-weight: 600;
    align-self: flex-end;
    color: #eee;
    z-index: 1000;
    text-shadow: -4px -4px 8px rgba(255,255,255,0.4),
                4px 4px 8px rgba(0,0,0,0.08);
    cursor: pointer;
  }
  
  &__view {
    display: flex;
    flex: 1 25%;
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    z-index: 1400;

    &:before,
    &:after {
      position: absolute;
      pointer-events: none;
      content: '';
      height: 380px;
      width: 15%;
      z-index: 100;
      transition: opacity 0.3s ease;

      height: 200px;
      @media (min-height: 520px) {
        height: 255px;
      }
      @media (min-height: 655px) {
        height: 380px;
      }
    }
    
    &:after {
      right: 0;
      background: linear-gradient(90deg, transparent 5%, gray 95%);
      opacity: var(--opacity-list-right-shadow);
    }

    &:before {
      left: 0;
      background: linear-gradient(90deg, rgba(255,255,255,0.4) 5%, transparent 55%);
      // opacity: var(--opacity-list-left-shadow);
      opacity: 0;
    }
  }

  &__carousel {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    transform: translateX(var(--transform-list-x));
    transition: transform 0.45s ease;
    width: 100%;
    overflow: scroll;
    
    height: 225px;
    @media (min-height: 520px) {
      height: 280px;
    }
    @media (min-height: 655px) {
      height: 405px;
    }
  }
}
</style>
