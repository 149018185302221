<template>
  <router-view />
  <router-view class="sidebar" name="RightSidebar" />
</template>

<script>
// import { onMounted, onBeforeUnmount } from 'vue';
export default {
  name: 'App',
  setup() {
    // setup SOUNDCLOUD iframe api
    let tag = document.createElement('script');
    tag.src = "https://www.youtube.com/iframe_api";
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    // setup YOUTUBE iframe api
    tag = document.createElement('script');
    tag.src = 'https://w.soundcloud.com/player/api.js';
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    // const onWindowVisibilityChange = (event) => {
    //   console.log(event);
    // };
    // onMounted(() => {
    //   document.addEventListener('visibilitychange', onWindowVisibilityChange);
    // });
    // onBeforeUnmount(() => {
    //   document.removeEventListener('visibilitychange', onWindowVisibilityChange);
    // });
  }
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Roboto';
}

#app,
.app {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  min-height: 100vh;
  overflow: hidden;
  // blue // background-color: #8EC5FC;
  // blue // background-image: linear-gradient(62deg, #8EC5FC 0%, #E0C3FC 100%);
  // orange // background-color: #FBAB7E;
  // orange // background-image: linear-gradient(62deg, #FBAB7E 0%, #F7CE68 100%);
  background-color: #FF9A8B;
  background-image: linear-gradient(90deg, #FF9A8B 0%, #FF6A88 55%, #FF99AC 100%);
  z-index: 1000;

  @media (prefers-color-scheme: dark) {
    background-color: #333;
    background-image: none;
  }
}

</style>
