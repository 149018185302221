import { createRouter, createWebHistory } from 'vue-router';
import { storeToRefs } from 'pinia';

import { usePlayerStore } from '../stores/player';

import Songs from '../views/Songs.vue';
import Playlists from '../views/Playlists.vue';
import Playlist from '../views/Playlist.vue';

const router = createRouter({
  history: createWebHistory(),
  routes: [{
    path: '/',
    name: 'home',
    component: Songs,
    beforeEnter: () => {
      const playerStore = usePlayerStore();
      const { playlists }  = storeToRefs(playerStore);
      const vvibePlaylistIndex = Object.values(playlists.value).findIndex((playlist) => (playlist.id === 'vvibe'));
      playlists.value[vvibePlaylistIndex].isCurrent = true;
    }
  }, {
    path: '/:title',
    name: 'songs',
    component: Songs,
    beforeEnter: (route) => {
      const playerStore = usePlayerStore();
      const { playlists }  = storeToRefs(playerStore);
      const playlistIndex = Object.values(playlists.value).findIndex((playlist) => (playlist.id === route.params.title)) 
      playlists.value[playlistIndex].isCurrent = true;
    }
  },
  {
    path: '/playlists',
    name: 'playlists',
    component: Playlists,
    beforeEnter: () => {
      const playerStore = usePlayerStore();
      const { isSetByUser } = storeToRefs(playerStore);

      isSetByUser.value = true;
      playerStore.setActiveSongState('pause');
    }
  }, {
    path: '/playlist/:id',
    name: 'playlist',
    component: Playlist,
    // for Playlist view .sidebar class && RightSidebar router-view name
    // components: {
    //   default: Songs,
    //   RightSidebar: Playlist,
    // },
    beforeEnter: () => {
      const playerStore = usePlayerStore();
      const { isSetByUser } = storeToRefs(playerStore);

      isSetByUser.value = true;
      playerStore.setActiveSongState('pause');
    }
  }]
});

export default router;
