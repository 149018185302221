import { createApp } from 'vue';
import pinia from './stores';
import router from './router';

import App from './App.vue';

import './assets/styles/btns.scss';

const app = createApp(App);

app.use(pinia).use(router).mount('#app');
