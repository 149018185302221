<template>
  <div class="song-card" :class="{'song-card--active': isSongActive}">
    <!-- <song-card-time :id="id" :time="time" /> -->
    <!-- <song-card-remove :id="id" /> -->
    <div v-if="playerName === 'youtube'" :id="`songcard${id}`" tabindex="-1"></div>
    <iframe v-else :id="`songcard${id}`" :src="videoId" allow="autoplay" tabindex="-1"></iframe>
  </div>
</template>

<script setup>
import { ref, toRefs, computed, onMounted, watch } from 'vue';
import { storeToRefs } from 'pinia';

// import SongCardTime from './SongCardTime.vue';
import SongCardRemove from './SongCardRemove.vue';

import { usePlayerStore } from '../stores/player';
import { useUrlParams } from '../composable/useUrlParams';

const props = defineProps({
  url: {
    type: String,
    default: null
  },
  id: {
    type: Number,
    default: 0
  },
  time: {
    // validator: (value) => {
    //   // const [val1, val2] = value.split(':');
    //   // return parseInt(val1, 10) < 59 && parseInt(val2, 10) < 59;
    // },
    type: String,
    default: '0:00'
  }
});

// 120 / 60 * 1000

const { getAllUrlParams } = useUrlParams();
const playerStore = usePlayerStore();
const { playlist, isSetByUser } = storeToRefs(playerStore);
const { activeSong, setActiveSong, setActiveSongState, playNextSong } = playerStore;
const { id: activeSongId, state: activeSongState } = toRefs(activeSong);

const wasPlayed = ref(false); // initially used mainly for Soundcloud player fix

const playerName = computed(() => {
  if (props.url.includes('youtube')) return 'youtube'; // YOUTUBE //
  if (props.url.includes('soundcloud')) return 'soundcloud'; // SOUNDCLOUD //
  return null;
});
// const seekToTime = computed(() => {
//   if (playerName.value === 'youtube') { // YOUTUBE //
//     const time = getAllUrlParams(props.url)?.t;
//     return time?.split('s')[0];
//   }
//   return undefined;
// });
const videoId = computed(() => {
  // if (playerName.value === 'youtube') return `https://youtube.com/embed/${getAllUrlParams(props.url)?.v}?enablejsapi=1`; // add origin url param
  return props.url;
});
const isSongActive = computed(() => (props.id === activeSongId.value));
const isSongOnRepeat = computed(() => (!!playlist.value.songs[props.id].repeat))

var widget = document.getElementById('sc-widget');
let player = ref(null);
const addSongTitleToPlaylist = (title) => {
  playlist.value.songs[props.id].title = title;
};
const playFixFocusSoundcloud = () => {
  widget.play(); // needed to fix the bug with auto focus on soundcloud iframe on first pause
  widget.pause(); // needed to fix the bug with auto focus on soundcloud iframe on first pause
  widget.play(); // needed to fix the bug with auto focus on soundcloud iframe on first pause
  wasPlayed.value = true;
};
const setSongIsReady = (isReady = true) => {
  playlist.value.songs[props.id].ready = isReady;
};
const setPlayer = () => {
  if (playerName.value === 'youtube') { // YOUTUBE //
    setSongIsReady(false);
    player.value = new YT.Player(`songcard${props.id}`, {
      videoId: `${getAllUrlParams(props.url)?.v}`,
      playerVars: { 'autoplay': 0 },
      events: {
        'onReady': (event) => { // READY
          player.value.setVolume(100); // Set max volume
          setSongIsReady();
          addSongTitleToPlaylist(event.target.videoTitle);
          // seekToTime.value && player.value.seekTo(seekToTime.value);
        },
        'onStateChange': (event) => {
          if (event.data === 1) { // PLAY
            setActiveSong(props.id);
            isSetByUser.value = false;
          }
          if (event.data === 2) { // PAUSE
            props.id === activeSongId.value && setActiveSongState('pause');
            isSetByUser.value = false;
          }
          if (event.data === 0) { // STOP/END
            // player.value.seekTo(seekToTime.value);
            if (isSongOnRepeat.value) player.value.playVideo();
            else playNextSong();
          }
        }
      }
    });
  }

  if (playerName.value === 'soundcloud') { // SOUNDCLOUD //
    player = document.getElementById(`songcard${props.id}`);
    widget = SC.Widget(player);
    widget.bind(SC.Widget.Events.READY, () => { // READY
      widget.setVolume(100); // Set max volume
      setSongIsReady();
      widget.getCurrentSound((sound) => {
        addSongTitleToPlaylist(sound.title);
      });

      widget.bind(SC.Widget.Events.PLAY, () => { // PLAY
        if (!wasPlayed.value) {
          playFixFocusSoundcloud();
        } else {
          setActiveSong(props.id);
          isSetByUser.value = false;
        }
      });

      widget.bind(SC.Widget.Events.PAUSE, (event) => {
        if (wasPlayed.value) { // PAUSE
          props.id === activeSongId.value && setActiveSongState('pause');
          isSetByUser.value = false;
        }
        if (event.relativePosition > 0.98) { // STOP/END
          if (isSongOnRepeat.value) widget.play();
          else playNextSong();
        }
      });
    });
  }
};
onMounted(() => setPlayer());

const playSong = () => {
  if (playerName.value === 'youtube') { // YOUTUBE //
    if (player.value?.getPlayerState) {
      player.value.getPlayerState() === 0 && player.value.playVideo(); // needed to fix youtube video not clearing after playing whole song and stopping
      player.value.playVideo();
    }
  }
  if (playerName.value === 'soundcloud') { // SOUNDCLOUD //
    if (!wasPlayed.value) {
      playFixFocusSoundcloud();
    }
    wasPlayed.value && widget.play();
  }
};
const stopSong = () => {
  if (playerName.value === 'youtube' && player.value?.getPlayerState) player.value.pauseVideo(); // YOUTUBE //
  if (playerName.value === 'soundcloud') widget.pause(); // SOUNDCLOUD //
}
watch(() => activeSongId.value, (id, oldId) => {
  if (oldId === props.id) stopSong();
  if (id === props.id && isSetByUser.value) playSong();
});
watch(() => activeSongState.value, (state) => {
  if (activeSongId.value === props.id && state === 'play' && isSetByUser.value) playSong();
  if (activeSongId.value === props.id && state === 'pause' && isSetByUser.value) stopSong();
});
watch(() => props.id, (id) => { // fix for adding new song to playlist
  if (id - 1 === activeSongId.value && activeSongState.value === 'play') setActiveSong(id, true);
});
</script>

<style lang="scss">
:root {
.song-card {
  $this: &;

  position: relative;
  flex: 0 0 335px; // 335 + 16 + 16 //
  margin: 0 16px;      // ^ 16 + 16 //
  // height: 380px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.45s ease-in-out;
  z-index: 1650;
  border: none;

  height: 200px;
  @media (min-height: 520px) {
    height: 255px;
  }
  @media (min-height: 655px) {
    height: 380px;
  }

  > iframe {
    // height: 380px;
    width: 335px;
    z-index: 1850;
    border: 0;

    height: 200px;
    @media (min-height: 520px) {
      height: 255px;
    }
    @media (min-height: 655px) {
      height: 380px;
    }
  }

  &:hover {
    > #{this}__overlay {
      opacity: 0.9;
      background: linear-gradient(0deg, gray 5%, transparent 80%);
    }
  }

  &--active {
    // box-shadow: 0 0 3px 3px #FF6A88;
    box-shadow: -3px -3px 3px 2px lighten(#FF6A88, 8%),
                3px 2px 3px 2px lighten(#db6f73, 7%);

    @media (prefers-color-scheme: dark) {
      $light-hex-color: unquote(var(--light-hex-color));
      $dark-hex-color: unquote(var(--dark-hex-color));
      // box-shadow: -3px -3px 4px 2px lighten($light-hex-color, 8%),
                  //  3px 2px 4px 2px darken($dark-hex-color, 7%);
      box-shadow: -3px -3px 4px 2px $light-hex-color,
                   3px 2px 4px 2px darken $dark-hex-color;
    }
  }
}
}
</style>
