<template>
  <div class="songs-nav">
    <div class="songs-nav__prev">
      <!-- <button class="songs-nav__prev-pos btn-neu" :class="{ 'btn-neu--disabled': isDisabledPosPrevSong }"
              @click="positionPreviousSong"> <span>&nearr;</span>
      </button> -->
      <button class="songs-nav__prev-track btn-neu" :class="{ 'btn-neu--disabled': isDisabledPlayPrevSong }"
              @click="throttlePlayPreviousSong"> <img :src="SkipPrev" />
      </button>
    </div>
    <songs-nav-play />
    <div class="songs-nav__next">
      <div class="songs-nav__repeat">
        <songs-nav-repeat />
      </div>
      <!-- <button class="songs-nav__next-pos btn-neu" :class="{ 'btn-neu--disabled': isDisabledPosNextSong }"
              @click="positionNextSong"> <span>&nearr;</span>
      </button> -->
      <button class="songs-nav__next-track btn-neu" :class="{ 'btn-neu--disabled': isDisabledPlayNextSong }"
              @click="throttlePlayNextSong"> <img :src="SkipNext" />
      </button>
    </div>
  </div>
</template>

<script setup>
import { computed, toRefs } from 'vue';
import { storeToRefs } from 'pinia';
import { useThrottleFn } from '@vueuse/core';

import { usePlayerStore } from '../stores/player';

import SongsNavPlay from './SongsNavPlay.vue';
import SongsNavRepeat from './SongsNavRepeat.vue';
import SkipPrev from '../assets/svg/skip-prev.svg';
import SkipNext from '../assets/svg/skip-next.svg';

const playerStore = usePlayerStore();
const { isSetByUser, songs } = storeToRefs(playerStore);
const { activeSong, positionPreviousSong, positionNextSong, playPrevSong, playNextSong } = playerStore;
const { position, id } = toRefs(activeSong);

const isDisabledPosPrevSong = computed(() => (position.value === 0));
const isDisabledPlayPrevSong = computed(() => (id.value === 0));
const isDisabledPosNextSong = computed(() => (position.value + 1 === songs.value.length));
const isDisabledPlayNextSong = computed(() => (id.value + 1 >= songs.value.length));

const throttleTime = 150;
const throttlePlayPreviousSong = useThrottleFn(() => {
  isSetByUser.value = true;
  playPrevSong();
}, throttleTime);
const throttlePlayNextSong = useThrottleFn(() => {
  isSetByUser.value = true;
  playNextSong();
}, throttleTime);
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');

:root {
  --pos-border-radius: 25px;
  --pos-width: 88px;
  --pos-width-inside: 80px;
  --top-left-position: 4px;
}

.songs-nav {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 25%;
  width: 100%;
  margin-top: 10px;
  z-index: 1700;

  &__prev,
  &__next {
    display: flex;
    flex-direction: column;
    flex: 100%;
  }

  &__prev {
    align-items: flex-end;
  }

  &__next {
    align-items: flex-start;
  }

  &__repeat {
    display: flex;
    flex: 1 1 100px;
    align-self: flex-start;
    padding-left: 11px;
    margin: -24px 35px 0 -4px;
  }

  &__left {
    flex: 1 auto;

    @media (min-width: 460px) {
      flex: 1 42px;
    }
  }

  &__prev-pos,
  &__next-pos {
    width: var(--pos-width);
    height: 51px;
    font-size: 33px;
    background: lighten(#FF6A88, 2%);
    border-radius: var(--pos-border-radius);
    color: #eee;

    &:before {
      border-radius: var(--pos-border-radius);
      top: var(--top-left-position);
      left: var(--top-left-position);
      width: var(--pos-width-inside);
      height: 43px;
    }
  }

  &__prev-pos > span {
    transform: rotate(225deg);
  }

  &__next-pos > span {
    transform: rotate(45deg);
  }

  &__prev-track,
  &__next-track {
    background: lighten(#FF6A88, 2%);
    width: 60px;
    height: 60px;
    margin: 20px 35px 20px 35px;
    border-radius: 50%;
    color: #eee;

    > img {
      z-index: 100;
    }

    &::before {
      top: 4px;
      left: 4px;
      width: 52px;
      height: 52px;
      border-radius: 50%;
      transition: box-shadow 0.2s ease, background-color 0.2s ease;
    }
  }

  &__next-track {
    margin-top: -56px;
  }
}
</style>
