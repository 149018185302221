<template>
  <div class="playlist" ref="playlistRef">
    <div class="playlist__wrapper">
      <div class="playlist__header">
        <button class="playlist__back btn-neu2"
                @click="goBack"> <span>&#10132;</span>
        </button>
        <div class="playlist__title"
             @click="showPlaylistSongs">{{ playlist.title ?? '' }}</div>
      </div>
      <TransitionGroup name="list" tag="div" class="playlist__list">
        <div v-for="(song, index) in songs" :key="song.key" :id="index"
            class="playlist__song btn-neu2" :class="{'playlist__song--dragged-on': index == targetElementAllowDrop}"
            draggable="true"
            v-on:dragstart="dragStart" v-on:dragend="dragEnd"
            v-on:drop.prevent="drop" v-on:dragover.prevent="allowDrop">
          <span>{{ `${index + 1}. ${song?.title}` }}</span>
          <button class="playlist__song-remove btn-neu2"
                  @click="removeSong(index)">&#215;</button>
        </div>
      </TransitionGroup>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { storeToRefs } from 'pinia';
// import { useElementHover } from '@vueuse/core'; // for Playlist view .sidebar class && RightSidebar router-view name
import { useRoute, useRouter } from 'vue-router';

import { usePlayerStore } from '../stores/player';

const route = useRoute();
const router = useRouter();

const playerStore = usePlayerStore();
const { playlists } = storeToRefs(playerStore);

const playlist = computed(() => (playlists.value[route.params.id]));
const songs = computed(() => (playlist.value.songs ?? []));

const goBack = () => {
  router.push('/playlists');
};
const showPlaylistSongs = () => {
  router.push(`/${playlist.value.id}`);
};

const removeSong = (index) => {
  playlists.value[route.params.id].songs.splice(index, 1);
};

// for Playlist view .sidebar class && RightSidebar router-view name
// const blockIframePointerEvents = (block = true) => { // fix drag and drop being blocked by underlying iframe windows
//   const iframes = document.getElementsByTagName('iframe');
//   Array.from(iframes).map((iframe) => { iframe.style.pointerEvents = block ? 'none' : 'all'; });
// };
// const playlistRef = ref(null);
// const isPlaylistHovered = useElementHover(playlistRef);
// watch(() => isPlaylistHovered.value, (isHoveredOver) => {
//   blockIframePointerEvents(isHoveredOver);
// });

let id;
let nextItemId;
const movedSongId = ref(0);
const movedSong = ref(null);
const targetElementAllowDrop = ref(null);
const dragStart = ({ target }) => {
  id = parseInt(target.id, 10);
  movedSongId.value = id; // set item being moved (computed) css var here
  nextItemId = id + 1;
  movedSong.value = playlist.value.songs.slice(id, nextItemId)[0]; 
};
const dragEnd = () => {
  targetElementAllowDrop.value = null;
};
const drop = ({ target }) => {
  id = parseInt(target.id, 10);
  playlists.value[route.params.id].songs.splice(movedSongId.value, 1);
  playlists.value[route.params.id].songs.splice(id - 1 < 0 ? 0 : id, 0, movedSong.value);
  targetElementAllowDrop.value = null;
};
const allowDrop = (event) => {
  targetElementAllowDrop.value = parseInt(event.target.id, 10);
};
</script>

<style lang="scss" scoped>
.list-move,
.list-enter-active,
.list-leave-active {
  transition: transform 0.3s ease-out, opacity 0.30s ease-out;
}
.list-enter-from,
.list-leave-to {
  transform: translateX(-200px);
  opacity: 0;
}
.list-leave-active {
  position: absolute;
}

.playlist {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100vh;
  padding: 20px;
  background-color: #FF9A8B;

  @media (prefers-color-scheme: dark) {
    background-color: #333;
  }

  &.sidebar { // for Playlist view .sidebar class && RightSidebar router-view name
    // position: absolute;
    top: 0;
    right: 0;
    left: 50%;
    bottom: 0;
    z-index: 5000;
  }

  &__wrapper {
    display: grid;
    grid-template-columns: minmax(min-content, max-content);
    // grid-template-columns: minmax(min-content, min-content);
  }

  &__header {
    display: flex;
    flex: 1 0 auto;
    align-items: center;
  }

  &__back {
    flex: 0 0 auto;
    height: 56px;
    width: 56px;
    margin: 20px 20px 20px 0;
    border-radius: 50%;

    > span {
      font-size: 35px;
      transform: rotate(180deg);
    }

    &:before {
      top: 4px;
      right: 4px;
      left: 4px;
      bottom: 4px;
      border-radius: 50%;
    }
  }

  &__title {
    // flex: 1 0 auto;
    // flex-wrap: wrap;
    position: relative;
    color: #eee;
    font: 45px 'Varela Round', sans-serif;
    cursor: pointer;
  }

  &__list {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: calc(100vh - 110px);
    margin-block-start: 5px;
    // display: grid;
    // grid-template-columns: fit-content(min-content, max-content);
    // grid-template-rows: repeat(auto-fit, minmax(9%, min-content)); // !
    // grid-gap: 0.5rem; // !
  }

  &__song {
    position: relative;
    justify-content: space-between;
    padding: 14px 24px;
    margin: 0 0 20px 20px;
    color: #eee;
    border-radius: 5px;
    // box-shadow: 2px 2px 3px lighten(#ffb0a0, 1%),
    //            -2px -2px 3px darken(#d98276, 1%),
    //       inset 2px 2px 2px darken(#d98276, 1%),
    //      inset -2px -2px 2px lighten(#ffb0a0, 1%);
    // box-shadow: 4px 4px 10px darken(#d98276, 2%),
    //            -4px -4px 10px lighten(#ffb0a0, 2%);
    z-index: 1800;
    cursor: pointer;

    &--dragged-on {
      border: 1px dashed #4A90E2B3;
      z-index: 6000;
    }

    > span {
      text-align: left;
    }

    &-remove {
      flex: 0 0 auto;
      align-self: center;
      height: 26px;
      width: 26px;
      margin: 0 0 0 5px;
      border-radius: 50%;
      font-size: 20px;
      z-index: 2500;
      box-shadow: -3px -3px 6px #ffb0a0,
                   3px 3px 6px #d98276,
             inset 1px 1px 2px transparent, //#ffabbdd4
            inset -1px -1px 2px transparent; //#db6f85

      @media (prefers-color-scheme: dark) {
        box-shadow: -3px -3px 6px var(--light-hex-color),
                     3px 3px 6px var(--dark-hex-color),
               inset 1px 1px 2px transparent,
              inset -1px -1px 2px transparent;

      }

      &:before {
        top: 3px;
        left: 3px;
        right: 3px;
        bottom: 3px;
        border-radius: 50%;
        box-shadow: -8px -8px 16px #ffb0a0,
                     8px 8px 16px #d98276,
               inset 0 0 3px #ffb0a0, // inset 2px 2px 2px #ffabbdd4,
               inset 0 0 3px #d98276; // inset -2px -2px 2px #db6f85

        @media (prefers-color-scheme: dark) {
          box-shadow: -8px -8px 16px var(--light-hex-color),
                       8px 8px 16px var(--dark-hex-color),
                 inset 0 0 3px var(--light-hex-color),
                 inset 0 0 3px var(--dark-hex-color);
        }
      }

      &:hover {
        box-shadow: -1px -1px 2px #ffb0a0,
                     1px 1px 2px #d98276,
               inset 1px 1px 1px transparent, //#ffabbdd4
              inset -1px -1px 1px transparent; //#db6f85

        @media (prefers-color-scheme: dark) {
          box-shadow: -1px -1px 2px var(--light-hex-color),
                       1px 1px 2px var(--dark-hex-color),
                 inset 1px 1px 1px transparent,
                inset -1px -1px 1px transparent;
        }

        &:before {
          box-shadow: -5px -5px 10px #ffb0a0,
                       5px 5px 10px #d98276,
                 inset 0 0 1px #d98276, // inset -2px -2px 2px #db6f85
                inset -1px -1px 1px #ffb0a0; // inset 2px 2px 2px #ffabbdd4,
          
          @media (prefers-color-scheme: dark) {
            box-shadow: -5px -5px 10px var(--light-hex-color),
                         5px 5px 10px var(--dark-hex-color),
                   inset 0 0 1px var(--dark-hex-color),
                  inset -1px -1px 1px var(--light-hex-color);
          }
        }
      }
    }

    &:after,
    &:before {
      top: 4px;
      left: 4px;
      bottom: 4px;
      right: 4px;
      border-radius: 2px;
      z-index: 1500;
    }
    
    &:before {
      box-shadow: 0 0 0 lighten(#ffb0a0, 1%),
                  1px 1px 3px darken(#d98276, 1%);

      @media (prefers-color-scheme: dark) {
        box-shadow: 0 0 0 var(--light-hex-color),
                    1px 1px 3px var(--dark-hex-color);
      }
    }

    &:hover {
      // box-shadow: 1px 1px 1px darken(#d98276, 2%),
      //            -1px -1px 1px lighten(#ffb0a0, 2%);
      // box-shadow: 2px 2px 3px lighten(#ffb0a0, 1%),
      //            -2px -2px 3px darken(#d98276, 1%),

      &:after {
        // box-shadow: -6px -6px 14px lighten(#ffb0a0, 1%),
        //              6px 6px 14px darken(#d98276, 1%);
      }

      &:before {
        // box-shadow: 0 0 0px lighten(#ffb0a0, 1%),
        //             2px 2px 3px darken(#d98276, 1%);
      }
    }
  }
}
</style>