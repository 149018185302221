<template>
  <div class="playlists">
    <playlists-controls @showNewPlaylistTemplate="showNewPlaylistTemplate"/>
    <div class="playlists__wrapper">
      <playlists-card v-for="(playlist, index) in playlists" :key="playlist.title"
                      v-model="playlist.title"
                      :songs-count="playlist.songs.length"
                      :playlist-title="playlist.title"
                      @showPlaylist="showPlaylist(index)"/>
      <playlists-card v-if="isNewPlaylistTemplateVisible"
                      v-model="newPlaylist"
                      :isNewPlaylistTemplate="true"
                      @addPlaylist="addPlaylist"/>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useRouter } from 'vue-router';
import ShortUniqueId from 'short-unique-id';

import { usePlayerStore } from '../stores/player';

import PlaylistsControls from '../components/PlaylistsControls.vue';
import PlaylistsCard from '../components/PlaylistsCard.vue';

const router = useRouter();

const playerStore = usePlayerStore();
const { playlists } = storeToRefs(playerStore);

const showPlaylist = (index) => {
  Object.keys(playlists.value).map((key) => {
    playlists.value[key].isCurrent = false;
  });
  playlists.value[index].isCurrent = true;
  router.push(`/playlist/${index}`);
};

const newPlaylist = ref(null);
const isNewPlaylistTemplateVisible = ref(false);
const showNewPlaylistTemplate = () => {
  isNewPlaylistTemplateVisible.value = !isNewPlaylistTemplateVisible.value;
};
const addPlaylist = () => {
  const playlistIndex = playlists.value ? Object.keys(playlists.value)?.length : 0;
  const uid = new ShortUniqueId({length: 10});

  playlists.value = {
    ...playlists.value,
    ...{
      [playlistIndex]: {
        title: newPlaylist.value,
        isCurrent: false,
        songs: [],
        id: uid.rnd()
      }
    }
  };

  isNewPlaylistTemplateVisible.value = false;
  newPlaylist.value = ''; // clear input
};
// const { focused } = useFocus(newPlaylist); // @vueuse/core
// watchEffect(() => focused, () => {}, { flush: 'sync' });
</script>

<style lang="scss">
.playlists {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #FF9A8B;
  padding: 0 0 0 20px;
  min-height: 100vh;
  box-shadow: 0 0 10px 3px #FF9A8B,
              0 0 90px #FF9A8B;


  @media (prefers-color-scheme: dark) {
    background: #333;
    box-shadow: 0 0 10px 3px #333,
                0 0 90px #333;
  }

  &__wrapper {
    display: flex;
    flex: 5;
    min-height: 335px;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &:after {
    position: absolute;
    display: flex;
    content: '';
    top: 0;
    left: 20px;
    width: 96%;
    height: 100%;
    z-index: 100;
    box-shadow: 1px 1px 4px 2px darken(#FF9A8B, 2%),
                -1px -1px 4px 2px darken(#FF9A8B, 3%);
                // -1px -1px 4px 2px ligthen(#FF9A8B, 3%);

    @media (prefers-color-scheme: dark) {
      box-shadow: 1px 1px 4px 2px var(--light-hex-color),
                 -1px -1px 4px 2px var(--light-hex-color);
    }
  }
}
</style>