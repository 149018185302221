<template>
  <div class="songs-add">
    <Transition name="fade">
      <div v-show="isInputVisible" class="songs-add__wrapper btn-neu btn-neu--active">
        <input ref="inputRef" id="songs-add__input" class="songs-add__input" placeholder="Paste link and hit enter"
              @keydown.enter="addSong" autocomplete="off"
              @input.stop="validateUrl">
      </div>
    </Transition>
    <button class="songs-add__btn btn-neu"
            :class="{
              'songs-add__btn--active btn-neu--active': isInputVisible,
              'songs-add__btn--valid': isUrlValid,
              'songs-add__btn--invalid': !isUrlValid
            }"
            @click="showInput">
      <span>+</span>&#9835;
    </button>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import ShortUniqueId from 'short-unique-id';

import { usePlayerStore } from '../stores/player';

const playerStore = usePlayerStore();
const { playlist } = storeToRefs(playerStore);

const inputRef = ref(null);
const songUrl = ref(null);
const isInputVisible = ref(false);
const showInput = () => {
  isInputVisible.value = !isInputVisible.value;
};

const isUrlValid = ref(false);
const validateUrl = (event) => {
  const value = event.target.value;
  if (value.length > 10) {
    const urlSoundcloud = value.match(/https:\/\/w.soundcloud?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/); // https://stackoverflow.com/questions/3809401/what-is-a-good-regular-expression-to-match-a-url
    const urlYoutube = value.match(/https:\/\/www.youtube.com\/watch([^&]+)/) // YOUTUBE https://stackoverflow.com/questions/5237725/take-a-url-variable-with-regex
    if ((urlSoundcloud && urlSoundcloud[0]) || (urlYoutube && urlYoutube[0])) {
      isUrlValid.value = true;
      songUrl.value = !!urlSoundcloud ? urlSoundcloud[0] : urlYoutube[0];
    }
  } else {
    isUrlValid.value = false;
  }
};

const addSong = (event) => {
  if (isUrlValid.value) {
    const uid = new ShortUniqueId({ length: 10 });

    playlist.value.songs = [
      { name: songUrl.value, key: uid.rnd() },
      ...playlist.value.songs
    ];
  }
};
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active,
.fade-enter-active .songs-add__input,
.fade-leave-active .songs-add__input {
  transition: opacity 0.3s ease-out, width 0.3s ease;
  width: 220px;
  opacity: 1;
}

.fade-enter-from,
.fade-leave-to,
.fade-enter-from .songs-add__input,
.fade-leave-to .songs-add__input {
  width: 50px;
  opacity: 0;
  transition: opacity 0.3s ease-out, width 0.3s ease-out;
}

:root {
  .songs-add {
    position: relative;
    display: flex;
    flex: 1 1 auto;
    align-self: flex-end;
    justify-content: flex-end;
    margin: 0 0 -4px;

    &__wrapper {
      position: absolute;
      right: 2px;
      background: transparent;
      height: 50px;
      // width: 100%;
      border-radius: 25px;
      transition: opacity 0.3s ease-out, width 0.3s ease, box-shadow 0.3s ease;
      z-index: 1200;

      &:before {
        top: 3px;
        left: 3px;
        height: 44px;
        width: 100%;
        background: transparent;
        transition: opacity 0.3s ease-out, width 0.3s ease, box-shadow 0.3s ease;
        background: darken(#FF99AC, 5%);
        border-radius: 25px;
        z-index: 1200;

        @media (prefers-color-scheme: dark) {
          background: #333;
        }
      }
    }

    &__input {
      background: transparent;
      border: 0;
      height: 50px;
      width: 220px;
      padding: 15px 55px 15px 15px;
      text-align: left;
      border-top-right-radius: 25px;
      border-bottom-right-radius: 25px;
      transition: opacity 0.3s ease, width 0.3s ease;
      z-index: 1300;
      color: #eee;

      &::placeholder {
        color: #eee;
        opacity: 0.5;
      }

      &:focus {
        outline: none;
      }
    }

    &__btn {
      height: 50px;
      width: 50px;
      border-radius: 50px;
      font-size: 18px;
      background: darken(#FF99AC, 5%);
      z-index: 1500;
      padding: 0;

      @media (prefers-color-scheme: dark) {
        background: #333;
      }
      
      &::before {
        height: 44px;
        width: 44px;
        top: 3px;
        left: 3px;
        border-radius: 50px;
        z-index: 1500;
      }

      &--valid {
        color: lightgreen;
      }
    }
  }
}
</style>