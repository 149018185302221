<template>
  <button class="songs-repeat btn-neu" :class="{'songs-repeat--active btn-neu--active': isRepeatActive}"
          @click="repeat">
    <img :src="RepeatSquareIcon" />
  </button>
</template>

<script setup>
import { computed, toRefs } from 'vue';
import { storeToRefs } from 'pinia';

import RepeatSquareIcon from '../assets/svg/repeat-square-icon.svg';

import { usePlayerStore } from '../stores/player';

const playerStore = usePlayerStore();

const { playlist } = storeToRefs(playerStore);
const { activeSong } = playerStore;
const { id } = toRefs(activeSong);

const isRepeatActive = computed(() => (!!playlist.value.songs[id.value]?.repeat));

function repeat() {
  isRepeatActive.value ? playlist.value.songs[id.value].repeat = 0 : playlist.value.songs[id.value].repeat = 1;
};

</script>

<style lang="scss">
.songs-repeat {
  $this: &;

  flex: 0 auto;
  padding: 0;
  height: 35px;
  width: 35px;
  color: darken(#D5FFEA, 10%);
  border-radius: 50%;

  box-shadow: -2px -2px 5px lighten(#FF6A88, 8%),
               2px 2px 5px lighten(#db6f73, 1%),
         inset 1px 1px 2px transparent, //#ffabbdd4
        inset -1px -1px 2px transparent !important; //#db6f85
                
  @media (prefers-color-scheme: dark) {
    box-shadow:  -2px -2px 5px rgba(250, 250, 250, 0.13),
                  2px 2px 5px rgba(0,0,0, 0.40),
            inset 1px 1px 2px transparent,
           inset -1px -1px 2px transparent !important;
  }
  
  &::before {
    top: 3px;
    left: 3px;
    width: 29px;
    height: 29px;
    border-radius: 50%;
  }

  > img {
    height: 15px;
    width: 15px;
    transition: filter 0.2s ease-out;
    filter: invert(97%) sepia(5%) saturate(218%) hue-rotate(88deg) brightness(120%) contrast(87%);
  }

  &--active {
    > img {
      // #6FFFB7
      filter: invert(100%) sepia(23%) saturate(4958%) hue-rotate(64deg) brightness(110%) contrast(100%);
    }
  }
}
</style>
